@import "../../index";

.attributes {
  display: flex;
  flex-wrap: wrap;

  @include media('<=desktop') {
    width: 100%;
  }

  & > dt, & > dd {
    width: 50%;

    @include media('<=tablet') {
      width: 100%;
    }
  }

  & > dt {
    @include brandonGrotesque();
    text-transform: uppercase;
    color: $purple-dark;
  }

  & > dd {
    @include proximaNova();
    color: $grey;
  }
}
