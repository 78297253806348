@import "../../index";

.orderFormProfileColorItem {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: .5rem;
  width: 25%;

  &:nth-of-type(4) {
    visibility: hidden;

    @include media('<=desktop') {
      display: none;
    }
  }

  @include media('<=desktop') {
    width: 50%;
  }

  @include media('<=tablet') {
    width: 100%;
  }

  & > span {
    @include proximaNova();
    letter-spacing: 0;
    font-weight: normal;
    color: $grey;
    margin-left: 1.5rem;
  }

  &:hover {
    background: #F0F0F0;
    cursor: pointer;
  }
}

.orderFormProfileColor {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid lightgray;
}

.orderFormProfileColorActive {
  position: absolute;
  right: 20px;
  top: 18px;
  display: inline-block;
  transform: rotate(45deg);
  height: 24px;
  width: 12px;
  border-bottom: 2px solid $purple-dark;
  border-right: 2px solid $purple-dark;
}

.active {
  background: #F0F0F0;
}
