@import "../../index";

.row {
  display: flex;

  @include media('<=tablet') {
    flex-wrap: wrap;
  }
}

.singleColumn {
  width: 100%;
  padding: 1rem 1rem 0 1rem;
}

.doubleColumn {
  width: 50%;
  padding: 1rem 1rem 0 1rem;

  @include media('<=tablet') {
    width: 100%;
  }
}

.oneThirdColumn {
  width: 33.3333%;
  padding: 1rem 1rem 0 1rem;

  @include media('<=tablet') {
    width: 100%;
  }
}

.twoThirdColumn {
  width: 66.6666%;
  padding: 1rem 1rem 0 1rem;

  @include media('<=tablet') {
    width: 100%;
  }
}

.submitColumn {
  padding-bottom: 1rem;

  & > button {
    min-width: 150px;
  }
}

.bonusFormSubmitted {
  display: inline-block;
  color: #1E5535;
  background: #D2F5E0;
  padding: 1rem 1.25rem;
  @include proximaNova;
}

.bonusFormError {
  display: inline-block;
  color: #8c0b1f;
  background: #f7a4b1;
  padding: 1rem 1.25rem;
  @include proximaNova;
  width: 100%;

  span {
    margin-left: .5rem;
  }

  ul {
    margin: .5rem 0 0 2rem;
    list-style-type: square;
  }
}
