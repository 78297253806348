@import '../../index';

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
  z-index: 1999;
  padding: 40px 0;
}

.modalContent {
  background: #fff;
  max-width: 1060px;
  margin: 0 auto;
  padding: 40px;
  position: relative;
}

.modalClose {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 30px;

  &:hover {
    cursor: pointer;
  }
}

.row {
  display: flex;

  @include media('<=tablet') {
    flex-wrap: wrap;
  }
}

.singleColumn {
  width: 100%;
  padding: 1rem 1rem 0 1rem;
}

.doubleColumn {
  width: 50%;
  padding: 1rem 1rem 0 1rem;

  @include media('<=tablet') {
    width: 100%;
  }
}

.oneThirdColumn {
  width: 33.3333%;
  padding: 1rem 1rem 0 1rem;

  @include media('<=tablet') {
    width: 100%;
  }
}

.twoThirdColumn {
  width: 66.6666%;
  padding: 1rem 1rem 0 1rem;

  @include media('<=tablet') {
    width: 100%;
  }
}

.submitColumn {
  padding-bottom: 1rem;

  & > button {
    min-width: 150px;
  }
}
