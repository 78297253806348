@import "../../index";

.document {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include media('<=tablet') {
    flex-direction: column;

    a {
      margin-bottom: .25rem;
    }
  }

  a {
    color: $purple-dark;
    @include proximaNova();

    &:hover {
      color: $purple-light;
      text-decoration: none;
    }
  }
}

