@import "index";

.afterSales {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.dynamicContent {
  position: relative;
}

.pushSpinner {
  margin-top: 4rem;
}

.downloads {
  position: relative;
  min-height: 5rem;
}

.content {
  max-width: 60%;

  @include media('<=desktop') {
    max-width: 100%;
  }
}

.p1 {
  padding: 1.25rem;
}

.pt1 {
  padding-top: 1.25rem;
}

.pb1 {
  padding-bottom: 1.25rem;
}

.plr1 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.moreInformation {
  margin-top: 1rem;

  & > button {
    margin: 0 .5rem;

    &:first-of-type {
      margin-left: 0;
    }

    @include media('<=tablet') {
      margin: .5rem 0;
    }
  }
}

.special {
  margin-top: 1rem;

  & > div {
    background: $purple-dark;

    h2 {
      color: #fff;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column50 {
  width: 50%;

  @include media('<=desktop-lg') {
    width: 100%;
  }
}

.list {
  list-style-type: square;
  @include proximaNova;
  margin-left: 1.75rem;

  li {
    color: $grey;
    font-size: 0.92rem;
    letter-spacing: 0;

    &::marker {
      color: $purple-dark;
      font-size: 1rem;
    }
  }
}

.orderHint {
  background: $grey;
  display: flex;
  align-items: center;

  @include media('<=tablet') {
    flex-direction: column;
  }
}

.orderHintIcon {
  padding: 2.5rem 2.5rem 2.5rem 5rem;

  @include media('<=tablet') {
    padding-left: 2.5rem;
    padding-bottom: 0;
  }

  svg {
    width: 5rem;
    filter: brightness(0) invert(1);
  }
}

.orderHintContent {
  padding: 2.5rem;

  h3, p {
    color: #fff;
  }

  p {
    max-width: 70%;
    margin-top: .5rem;

    @include media('<=tablet') {
      max-width: 100%;
    }
  }

  button {
    border: 1px solid #fff;
    color: #fff;
    background: $grey;
    @include brandonGrotesque;
    padding: .5rem 1rem;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      background: #fff;
      color: $grey;
    }
  }
}

.materialLegend {
  display: flex;

  @include media('<=tablet') {
    flex-direction: column;
  }
}

.materialLegendIcon {
  margin-right: 2rem;
  display: flex;
  align-items: center;

  @include media('<=tablet') {
    margin-right: 0;
    margin-top: .5rem;
  }

  span {
    padding-left: .5rem;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.materialLegendDescription {
  margin: 1.5rem 0;
}

.materialColors {
  display: flex;
  flex-wrap: wrap;
}

.profileColors {
  display: flex;
  flex-wrap: wrap;
}

.checkbox {
  width: 40px;
  height: 40px;

  @include media('<=tablet') {
    width: 20px;
    height: 20px;
  }
}

.separation {
  display: flex;
  position: relative;

  @include media('<=tablet') {
    flex-direction: column;
  }
}

.separationImage {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  @include media('<=tablet') {
    top: -20px !important;
    right: 0;
    position: absolute;
  }

  svg {
    width: 10rem;

    @include media('<=tablet') {
      width: 5rem;
    }
  }
}

.separationText {
  & > p {
    @include proximaNova();
    font-weight: normal;
    margin-top: 1rem;

    &:nth-of-type(1) {
      @include media('<=tablet') {
        padding-right: 6rem;
      }
    }
  }
}

.separationTextTitle {
  @include brandonGrotesque;
  text-transform: uppercase;
  font-weight: bolder;

  @include media('<=tablet') {
    margin-top: 1.5rem;
  }
}

.separationEnterInformation {
  display: flex;
  gap: 1rem;

  & > div {
    width: 100%;
  }
}

.label {
  display: flex;
  align-items: center;
  margin: 0;

  input {
    width: 23px;
    height: 23px;
    margin-right: 1.5rem;
  }
}

.control {
  display: flex;
  align-items: center;

  &:nth-of-type(2) {
    margin-left: 2rem;

    span {
      margin-right: 1.5rem;
    }
  }
}

.orderImage {
  & > div {
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 75% !important;
  }
}
