@import "../../index";

.positions {
  margin-top: 1rem;

  & > button {
    margin: 0 .5rem;

    &:first-of-type {
      margin-left: 0;
    }

    @include media('<=tablet') {
      margin: .5rem 0;
    }
  }
}
