@import "../../index";

.drawing {
  position: relative;
  min-height: 5rem;

  canvas {
    max-width: 100%;
  }
}

