@import "../../index";

.orderFormColorItem {
  position: relative;
  display: flex;
  align-items: center;
  width: 25%;
  margin-bottom: 1.5rem;
  padding: .5rem;

  @include media('<=desktop') {
    width: 50%;
  }

  @include media('<=tablet') {
    width: 100%;
  }


  &:hover {
    background: #F0F0F0;
    cursor: pointer;

    .orderFormColorHover {
      display: block;
    }
  }

  & > span {
    @include proximaNova();
    letter-spacing: 0;
    font-weight: normal;
    color: $grey;
    margin-left: 1.5rem;
  }
}

.orderFormColorPreview {
  width: 3rem;
  height: 3rem;
  position: relative;
  border: 1px solid lightgray;

  & > div {
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      bottom: 0;
    }
  }
}

.orderFormColorHover {
  display: none;
  position: absolute;
  z-index: 2;
  bottom: 100%;
  left: 0;
  width: 100%;
  background: #F0F0F0;
  padding: 1rem;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
  }
}

.orderFormColorActive {
  position: absolute;
  right: 20px;
  top: 18px;
  display: inline-block;
  transform: rotate(45deg);
  height: 24px;
  width: 12px;
  border-bottom: 2px solid $purple-dark;
  border-right: 2px solid $purple-dark;
}

.active {
  background: #F0F0F0;
}
